import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "../../Basic/Simple/Buttons/ButtonCancel";
import ButtonCreateOrUpdate from "../../Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import TabsFixedSize from "src/components/Basic/Mixed/Tabs/TabsFixedSize";
import _c from "src/constants/Constants";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { Box, LinearProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetCurrenciesQuery } from "src/redux/services/CurrencyService";
import { useGetTermsOfPaymentsQuery } from "src/redux/services/TermsofpaymentService";
import { useTranslation } from "react-i18next";
import {
  Customer,
  CustomerType,
} from "src/accurasee-backend-types/app/customer/customer.types";
import {
  getStructure,
  ItemsTypes,
  GetStructureExtraProps,
  toSubmitData,
} from "./CustomerContainerStructure";
import {
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
} from "src/redux/services/CustomerService";
import ButtonClear from "../../Basic/Simple/Buttons/ButtonClear";
import ButtonBack from "../../Basic/Simple/Buttons/ButtonBack";
import useConfirmLeave from "src/hooks/useConfirmLeave";
import isDisableSubmit from "src/utils/isDisableSubmit";

type CustomerContainerTProps = {
  data?: Customer;
  setOpenModal?: (v: boolean) => void;
  postCreate?: (data: Customer) => void;
  rerouteUrlOnSuccess?: { create?: () => string };
};

const TABS = ["Basic details *", "Invoice details", "ROT/RUT"];

export const CustomerContainer = ({
  data,
  setOpenModal,
  postCreate,
  rerouteUrlOnSuccess,
}: CustomerContainerTProps) => {
  const [t] = useTranslation();

  const [isShowingVatWarning, setIsShowingVatWarning] = useState(false);

  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  // Automatically calculate customerNumber
  // By adding 1 or setting as 1 if no existing Customer
  const initialFormData =
    data ??
    ({
      ..._c.INITIAL_CUSTOMER_DATA,
      currencyCode: company?.currencyCode,
    } as Customer);

  const [createCustomer] = useCreateCustomerMutation();
  const [updateCustomer] = useUpdateCustomerMutation();

  const { data: termsOfPayments, isLoading: isLoadingTermsOfPayment } =
    useGetTermsOfPaymentsQuery(undefined);

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];
  const { data: currenciesResponse, isLoading: isLoadingCurrencies } =
    useGetCurrenciesQuery(undefined);

  const currencies = currenciesResponse?.data || [];

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<Partial<Customer>, GetStructureExtraProps, ItemsTypes>({
    getStructure,
    extraProps: {
      currencies,
      isCreate: !data,
      isShowingVatWarning,
      termsOfPayments,
      users,
    },
    initialFormData,
  });

  useEffect(() => {
    setIsShowingVatWarning(formData?.reversedConstructionVAT || false);
  }, [formData?.reversedConstructionVAT]);

  const resetData = () => {
    setFormData(initialFormData);
  };

  const initialSubmitData = {};

  const submitData = toSubmitData({
    data: formData,
    initData: initialFormData,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      name: "Customer",
      data: {
        create: submitData,
        update: submitData,
      },
      dataId: String(data?._id) || "",
      apiMutations: {
        create: createCustomer,
        update: updateCustomer,
      },
      ...rerouteUrlOnSuccess,
    },
    onSuccess: ({ responseData }) => {
      setHasTriedToSubmit(false);
      resetData();
      if (setOpenModal) setOpenModal(false);

      if (postCreate && responseData?.create) {
        const resData = responseData?.create;
        postCreate(resData);
      }
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  const isCreate = !data;

  useConfirmLeave({
    when:
      !isCreate &&
      !isDisableSubmit({
        data: submitData,
        initialData: initialSubmitData,
        isValid: isFormValid,
      }),
  });

  if (
    isLoadingCompany ||
    isLoadingTermsOfPayment ||
    isLoadingUsers ||
    isLoadingCurrencies
  ) {
    return <LinearProgress />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit({ action: isCreate ? "create" : "update" });
      }}
      ref={refForm}
    >
      <SectionContainer>
        <Section boxStyle={{ paddingTop: "3rem", width: "100%" }}>
          <TabsFixedSize
            name="customer-form"
            tabs={TABS}
            disabled={[
              false,
              false,
              // disabled unless Customer type is PRIVATE AND Country is Sweden
              formData?.customerType !== CustomerType["Private"] ||
                formData?.countryCode !== "SE",
            ]}
          >
            <Grid container columnSpacing={1} rowSpacing={2}>
              {BuildItems({
                items: structure.items.filter(
                  (item) => item.itemType === "basic_details",
                ),
                ...commonPropsBuildItem,
              })}
            </Grid>

            <>
              <Box
                key={"payment-delivery-terms"}
                sx={{
                  marginBottom: "var(--space-16px)",
                }}
              >
                <Typography
                  sx={{ paddingBottom: "var(--space-8px)" }}
                  color="var(--black-7)"
                  variant="body2"
                >
                  {t("Payment and delivery terms")}
                </Typography>
                <Grid container spacing={1}>
                  {BuildItems({
                    items: structure.items.filter(
                      (item) =>
                        item.itemType === "invoice_details_term_of_payment",
                    ),
                    ...commonPropsBuildItem,
                  })}
                </Grid>
              </Box>
              <Box key={"invoicing"} sx={{ marginBottom: "var(--space-16px)" }}>
                <Typography
                  sx={{ paddingBottom: "var(--space-8px)" }}
                  color="var(--black-7)"
                  variant="body2"
                >
                  {t("Invoicing")}
                </Typography>
                <Grid container spacing={1}>
                  {BuildItems({
                    items: structure.items.filter(
                      (item) => item.itemType === "invoice_details_invoicing",
                    ),
                    ...commonPropsBuildItem,
                  })}
                </Grid>
              </Box>
              <Box
                key={"references"}
                sx={{ marginBottom: "var(--space-16px)" }}
              >
                <Typography
                  sx={{ paddingBottom: "var(--space-8px)" }}
                  color="var(--black-7)"
                  variant="body2"
                >
                  {t("References")}
                </Typography>
                <Grid container spacing={1}>
                  {BuildItems({
                    items: structure.items.filter(
                      (item) => item.itemType === "invoice_details_references",
                    ),
                    ...commonPropsBuildItem,
                  })}
                </Grid>
              </Box>
            </>

            <Grid container spacing={1}>
              {BuildItems({
                items: structure.items.filter(
                  (item) => item.itemType === "rotrut",
                ),
                ...commonPropsBuildItem,
              })}
            </Grid>
          </TabsFixedSize>
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer>
        {!setOpenModal && <ButtonBack />}
        <ButtonGroup>
          {!setOpenModal ? (
            <ButtonClear
              onClick={() => {
                resetData();
              }}
            />
          ) : (
            <ButtonCancel
              onClick={() => {
                resetData();
                if (setOpenModal) setOpenModal(false);
              }}
            />
          )}
          <ButtonCreateOrUpdate
            initialSubmitData={initialSubmitData}
            isSubmitting={isSubmitting}
            isValid={isFormValid}
            label={isCreate ? "create" : "update"}
            onSubmit={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
            submitData={submitData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </form>
  );
};

export default CustomerContainer;
