import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonCreateOrUpdate from "src/components/Basic/Simple/Buttons/ButtonCreateOrUpdate";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ContractArticles from "src/components/Complex/Containers/ContractArticles";
import Page from "src/components/Basic/Mixed/Pages/Page";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionFloatingForButtons from "src/components/Basic/Simple/Sections/SectionFloatingForButtons";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useFormGlue from "src/hooks/useFormGlue";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useReload from "src/hooks/useReload";
import useTranslationWrapper from "src/hooks/useTranslationWrapper";
import {
  ContractTypeExtended,
  getStructure,
  GetStructureExtraProps,
  ItemsTypes,
  toData,
  toSubmitData,
} from "./ContractTypeContainerGetStructure";
import {
  ContractArticle,
  ContractDimension,
  ContractType,
} from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import NotFound from "src/components/Screens/NotFound/NotFound";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetDimensionsQuery } from "src/redux/services/DimensionService";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  useCreateContractTypeMutation,
  useGetContractTypeQuery,
  usePatchContractTypeMutation,
} from "src/redux/services/ContractTypesServices";
import {
  useGetActiveIndicesQuery,
  useGetIndexQuery,
} from "src/redux/services/IndexService";
import { escape } from "../../../../../utils/translate";
import ButtonBack from "../../../../../components/Basic/Simple/Buttons/ButtonBack";
import getSelectOptions from "../../../../../utils/getSelectOptions";
import useConfirmLeave from "src/hooks/useConfirmLeave";
import isDisableSubmit from "src/utils/isDisableSubmit";

export default function ContractTypeContainer() {
  const [t] = useTranslationWrapper();
  const { contractTypeId } = useParams<{ contractTypeId?: string }>();
  const { reloadKey, reload } = useReload();

  const {
    data: contractType,
    isLoading: isLoadingContractType,
    isError,
  } = useGetContractTypeQuery(contractTypeId, {
    skip: contractTypeId === undefined,
  });

  const { data: articleQuery, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);
  const articles = articleQuery?.data;

  const { data: company, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;
  const { data: dataQueryIndices, isLoading: isLoadingIndices } =
    useGetActiveIndicesQuery(undefined);
  const indexOptions = getSelectOptions({
    data: [...(dataQueryIndices?.data || [])].map((index) => ({
      label: `${index.name} (${index.value})`,
      value: String(index._id),
    })),
  });

  const { data: currentIndex, isLoading: isLoadingIndex } = useGetIndexQuery(
    contractType?.indexOption?.indexId,
    { skip: !contractType?.indexOption?.indexId },
  );

  if (
    !!currentIndex &&
    !indexOptions.map((v) => v.value).includes(String(currentIndex?._id))
  ) {
    indexOptions.push({
      label: `${currentIndex?.name} (${currentIndex?.value})`,
      value: String(currentIndex?._id),
      isActive: true,
    });
  }

  const [createContractType] = useCreateContractTypeMutation();
  const [patchContractType] = usePatchContractTypeMutation();
  // const [isContractFormValid, setIsContractFormValid] = useState(false);
  const isLoadingData =
    isLoadingArticles ||
    isLoadingContractType ||
    isLoadingCompany ||
    isLoadingDimensions ||
    isLoadingIndex ||
    isLoadingIndices;

  let projectNumberSeries = company?.company?.projectNumberSeries?.filter(
    (d) => d.isActive,
  );
  const companyHasBkConnection = Boolean(company?.company?.bkConnection);
  projectNumberSeries = [...(projectNumberSeries || [])];

  const projectNumberSeriesOptions = projectNumberSeries.map((item) => {
    let lastNumber = item.lastNumber;
    if (lastNumber === "Infinity") {
      lastNumber = t("Infinity");
    }

    let label = item.label;
    if (label === "Default") {
      label = t("Default");
    }

    const prefix = item.prefix ? item.prefix : "";

    return {
      label: `${label}: ${prefix}${item.firstNumber} - ${prefix}${lastNumber}`,
      value: item.id,
      isActive: item.isActive,
    };
  });

  const initialFormData = toData({
    data: contractTypeId
      ? contractType
      : ({
          cancellationTerms: {
            number: 1,
            unit: "month",
          },
          contractDimensions:
            dimensions?.map(
              (d) =>
                ({
                  dimensionId: d._id,
                  name: d.name,
                  active: false,
                }) as ContractDimension,
            ) || [],
          indexOption: {
            index: false,
          },
          renewalTerms: {
            number: 1,
            unit: "month",
            renewalType: "automatic",
          },
        } as ContractType),
    extraProps: { dimensions },
  });

  const {
    formData,
    hasTriedToSubmit,
    helperText,
    isFormValid,
    setFormData,
    // setHelperText,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<
    ContractTypeExtended,
    GetStructureExtraProps,
    ItemsTypes
  >({
    initialFormData,
    extraProps: {
      companyHasBkConnection,
      currentIndex,
      dimensions: dimensions || [],
      indexOptions,
      projectNumberSeriesOptions,
    },
    getStructure,
  });

  const formGlueArticles = useFormGlue<ContractArticle[]>();

  const data = {
    ...toSubmitData({
      data: { ...formData, contractArticles: formGlueArticles.submitData },
      initData: initialFormData,
    }),
  };

  const initialData = {
    ...toSubmitData({
      data: {
        ...initialFormData,
        contractArticles: formGlueArticles.submitDataInitial,
      },
      initData: initialFormData,
    }),
  };

  useEffect(() => {
    if (currentIndex?.active === false && !hasTriedToSubmit) {
      setHasTriedToSubmit(true);
    }
  }, [currentIndex, hasTriedToSubmit]);

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createContractType,
        update: patchContractType,
      },
      data: {
        create: data,
        update: data,
      },
      dataId: contractTypeId,
      name: "Contract type",
      rerouteUrlOnSuccess: {
        create: ({ responseData }) =>
          `/app/contracts/contracttypes/${responseData?._id}/overview`,
      },
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
      formGlueArticles.setHasTriedToSubmit(false);
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  const isAllFormValid = isFormValid && formGlueArticles.isFormValid;

  const isCreate = !contractTypeId;

  useConfirmLeave({
    when:
      !isCreate &&
      !isDisableSubmit({
        data,
        initialData,
        isValid: isAllFormValid,
      }),
  });

  return (
    <Page
      label={contractTypeId ? "Edit contract type" : "Create new contract type"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        { label: "Contract types", link: "/app/contracts/contracttypes" },
        {
          label: contractTypeId
            ? `Contract type${escape(` ${contractType?.number || ""}`)}`
            : "Create new contract type",
        },
      ]}
      isLoading={isLoadingData}
    >
      {isError ? (
        <NotFound type="contract_type_list" />
      ) : (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onFormSubmit({
                action: contractTypeId ? "update" : "create",
              }).catch((err) => console.log(err));
            }}
            ref={refForm}
          >
            <SectionContainer>
              <Section
                key={"contract-info"}
                label={"Contract info"}
                md={12}
                lg={6}
              >
                {BuildItems({
                  items: structure.items.filter(
                    (item) => item.itemType === "info",
                  ),
                  ...commonPropsBuildItem,
                })}
              </Section>
              <Section key={"renewal"} label={"Renewal"} md={12} lg={6}>
                {BuildItems({
                  items: structure.items.filter(
                    (item) => item.itemType === "renewal",
                  ),
                  ...commonPropsBuildItem,
                })}
              </Section>
              <ContractArticles
                articleList={articles}
                contractArticlesInit={contractType?.contractArticles}
                currencyCode={"SEK"}
                editRights={false}
                expandedArticles
                formGlue={formGlueArticles}
                isIndex={formData?.indexOption?.index}
                key={reloadKey}
                label="Contract articles"
              />
            </SectionContainer>
          </form>

          {/* Floating Button Wrapper as a Footer*/}
          <SectionFloatingForButtons isFullWidth>
            <SectionWithButtonContainer sx={{ margin: 0 }}>
              {contractTypeId && <ButtonBack />}
              <ButtonGroup>
                <ButtonClear
                  onClick={() => {
                    reload();
                    setFormData(initialFormData);
                  }}
                />
                <ButtonCreateOrUpdate
                  id={
                    contractTypeId
                      ? "update-contract-type"
                      : "Create-contract-type"
                  }
                  initialSubmitData={initialData}
                  isSubmitting={isSubmitting}
                  isValid={isAllFormValid}
                  label={contractTypeId ? "update" : "create"}
                  onSubmit={() => {
                    refForm.current.requestSubmit();
                    setHasTriedToSubmit(true);
                  }}
                  permissions={contractType?.permissions}
                  submitData={data}
                />
              </ButtonGroup>
            </SectionWithButtonContainer>
          </SectionFloatingForButtons>
        </>
      )}
    </Page>
  );
}
