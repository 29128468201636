import AddRowModalWithForm from "./modals/AddRowModalWithForm";
import BuilderColumnOptionsList from "src/components/Builders/Table/BuilderColumnOptionsList";
import ButtonAdd from "src/components/Basic/Simple/Buttons/ButtonAdd";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionTransparentForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionTransparentForTableWithToolbar";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import TranslationExportModal from "./modals/TranslationExportModal";
import TranslationImportModalWithForm from "./modals/TranslationImportModalWithForm";
import TranslationMissingModal from "./modals/TranslationMissingModal";
import flattenObject from "src/utils/flattenObject";
import useFormTable from "src/hooks/useFormTable";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Language } from "src/accurasee-backend-types/app/language/language.types";
import { Translation } from "src/accurasee-backend-types/app/translation/translation.types";
import { TranslationContext } from "../../../../context/TranslationProvider";
import { addImport, addRow } from "./utils";
import { useContext, useState } from "react";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import getStructure, {
  toData,
  toInitialSubmitData,
  toSubmitData,
} from "./TranslationsStructure";
import {
  useCreateTranslationsMutation,
  useDeleteTranslationsMutation,
  useUpdateTranslationsMutation,
} from "src/redux/services/TranslationService";

const Translations = ({
  companyId,
  isLoading,
  languages,
  translations,
}: {
  companyId?: string; // display Custom column for specific translation
  isLoading?: boolean;
  languages?: Language[];
  translations: Translation[];
}) => {
  const activeLanguages = languages?.filter((l) => l.isActive) || [];

  const [t] = useTranslation();
  const languageCodes = activeLanguages.map((l) => l.code);

  // import file modal
  const [openMissingModal, setOpenMissingModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openAddRowModal, setOpenAddRowModal] = useState(false);
  const [createTranslations] = useCreateTranslationsMutation();
  const [deleteTranslations] = useDeleteTranslationsMutation();
  const [updateTranslations] = useUpdateTranslationsMutation();
  const { missingTranslations } = useContext(TranslationContext);
  const initialFormData = toData({ data: translations });

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    setHelperText,
    structure,
  } = useFormTable({
    extraProps: { companyId, languages: activeLanguages },
    initialFormData,
    getStructure,
  });

  const submitData = toSubmitData({
    data: formData,
  });

  const initialSubmitData = toInitialSubmitData();

  const { onFormSubmit, refForm, isSubmitting } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        create: createTranslations,
        delete: deleteTranslations,
        update: updateTranslations,
      },
      data: submitData, // Remove unchanged, deleted key-value(s) and added blank row
      name: "Translations",
    },
    onSuccess: () => {
      setHasTriedToSubmit(false);
    },
  });

  const { options, searchedData } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
    searchOpen: true,
    filter: true,
  });

  const columns = BuilderColumnOptionsList({
    data: formData,
    helperText,
    items: structure.items,
  });

  return (
    <>
      <form
        ref={refForm}
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit();
        }}
      >
        <SectionContainer isLoading={isLoading}>
          <SectionTransparentForTableWithToolbar
            rightBoxArea={
              <ButtonGroup>
                <ButtonPrimary
                  disabled={missingTranslations.length === 0}
                  key={"missing"}
                  onClick={() => {
                    setOpenMissingModal(true);
                  }}
                >
                  {t("  Missing translations") +
                    (missingTranslations.length > 0
                      ? ` (${missingTranslations.length})`
                      : "")}
                </ButtonPrimary>
                <ButtonPrimary
                  key={"export"}
                  onClick={() => setOpenExportModal(true)}
                >
                  {t("Export")}
                </ButtonPrimary>
                <ButtonPrimary
                  key={"import"}
                  color="primary"
                  onClick={() => setOpenImportModal(true)}
                >
                  {t("Import")}
                </ButtonPrimary>
              </ButtonGroup>
            }
          >
            <MuiDataTableWrapper
              columns={columns}
              data={formData.map((f) => flattenObject(f))}
              noBackground={true}
              options={options}
              tableType="main-with-filter"
            />
          </SectionTransparentForTableWithToolbar>
          <SectionWithButtonContainer>
            <ButtonAdd
              label={"Add row"}
              onClick={() => setOpenAddRowModal(true)}
            />
            <ButtonGroup>
              <ButtonClear
                onClick={() => {
                  if (translations) {
                    setFormData(initialFormData);
                    setHasTriedToSubmit(false);
                  }
                }}
              />
              <ButtonUpdate
                submitData={submitData}
                initialSubmitData={initialSubmitData}
                isSubmitting={isSubmitting}
                isValid={isFormValid}
                onSubmit={() => {
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
              />
            </ButtonGroup>
          </SectionWithButtonContainer>
        </SectionContainer>
      </form>
      <AddRowModalWithForm
        handleCloseModal={(action: "add" | "cancel", data) => {
          if (data && action === "add") {
            const newFormData = addRow({
              data,
              initialData: translations,
              formData,
              languages: languageCodes,
            });
            setHelperText([...helperText, {}]);
            setFormData(newFormData);
          }

          setOpenAddRowModal(false);
        }}
        translations={translations}
        openModal={openAddRowModal}
      />

      <TranslationMissingModal
        handleCloseModal={() => setOpenMissingModal(false)}
        formData={formData}
        openModal={openMissingModal}
        setFormData={setFormData}
      />
      <TranslationExportModal
        handleCloseModal={() => setOpenExportModal(false)}
        translations={formData}
        searchedData={searchedData || []}
        openModal={openExportModal}
      />
      <TranslationImportModalWithForm
        openModal={openImportModal}
        translations={translations}
        handleCloseModal={(action, data, importType) => {
          if (action === "add" && data !== undefined) {
            const newFormData = addImport({
              activeLanguages: activeLanguages.map((a) => a.code),
              data,
              formData,
              importType,
            });

            setFormData(newFormData);

            setOpenImportModal(false);
          } else if (action === "cancel") {
            setOpenImportModal(false);
          }
        }}
      />
    </>
  );
};

export default Translations;
